export default [
  {
    path: '/helpdesks',
    name: 'helpdesks',
    component: () => import('@/views/helpdesk/HelpdeskList.vue'),
    props: true,
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'helpdesks',
    },
  },

  {
    path: '/clients/client-add',
    name: 'client-add',
    component: () => import('@/views/clients/AddClient.vue'),
    props: true,
    meta: {
      navActiveLink: 'clients',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/helpdesks/helpdesk-preview/:id',
    name: 'helpdesk-preview',
    component: () => import('@/views/helpdesk/PreviewHelpdesk.vue'),
    props: true, // Enable passing props via route
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'helpdesks',
    },
  },

  {
    path: '/helpdesks/prepworld',
    name: 'prepworld-general',
    component: () => import('@/views/helpdesk/ClientView.vue'),
    props: true, // Enable passing props via route
    meta: {
      Permission: 'no_permission_required',
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/faq/prepworld',
    name: 'prepworld',
    component: () => import('@/views/helpdesk/PrepworldFaq.vue'),
    props: true, // Enable passing props via route
    meta: {
      Permission: 'no_permission_required',
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/helpdesks/add-login',
    name: 'add-login',


    component: () => import('@/views/helpdesk/AddHelpdeskLogin.vue'),
    props: (route) => ({ clientId: route.query.clientId }),

    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'helpdesks',
    },
  },

  {
    path: '/helpdesks/edit-login/:id',
    name: 'edit-login',
    component: () => import('@/views/helpdesk/EditHelpdeskLogin.vue'),
    props: (route) => ({ clientId: route.query.clientId }),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'helpdesks',
    },
  },

  
];

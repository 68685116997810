export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/clients/ClientList.vue'),
    props: true,
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clients',
    },
  },

  {
    path: '/clients/client-add',
    name: 'client-add',
    component: () => import('@/views/clients/AddClient.vue'),
    props: true,
    meta: {
      navActiveLink: 'clients',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/clients/client-preview/:id',
    name: 'client-preview',
    component: () => import('@/views/clients/PreviewClient.vue'),
    props: true, // Enable passing props via route
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clients',
    },
  },

  // {
  //   path: '/client/client-edit/:id',
  //   name: 'client-edit',
  //   component: () => import('@/views/client/client-add/CustomerEdit.vue'),
  //   meta: {
  //     Permission: 'no_permission_required',
  //   },
  // },

  // {
  //   path: '/client/client-add',
  //   name: 'client-add',
  //   component: () => import('@/views/client/client-add/CustomerAdd.vue'),
  //   meta: {
  //     Permission: 'no_permission_required',
  //   },
  // },
];

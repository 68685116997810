export default [
  {
    path: '/user/user-list',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/user-add/UserEdit.vue'),
    meta: {
      navActiveLink: 'user-list',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-add',
    name: 'user-add',
    component: () => import('@/views/user/user-add/UserAdd.vue'),
    meta: {
      navActiveLink: 'user-list',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user/user-profile/AccountSetting.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: "/user/user-preview/:id",
    name: "user-preview",
    component: () => import("@/views/user/user-preview/UserView.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

];

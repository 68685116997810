export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/ProjectList.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'projects',
    },
  },

  {
    path: '/projects/project-add',
    name: 'project-add',
    component: () => import('@/views/projects/AddProject.vue'),
    props: true, // Enable passing props via route

    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'projects',
    },
  },

  {
    path: '/projects/project-edit',
    name: 'project-edit',
    component: () => import('@/views/projects/EditProject.vue'),
    props: true, // Enable passing props via route

    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'projects',
    },
  },

  {
    path: '/projects/project-preview/:id',
    name: 'project-preview',
    component: () => import('@/views/projects/PreviewProject.vue'),
    props: true, // Enable passing props via route

    meta: {
      Permission: 'no_permission_required',

      navActiveLink: 'projects',
    },
  },
];

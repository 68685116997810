export default [
  {
    path: '/portfolio/portfolio-list',
    name: 'portfolio-list',

    component: () => import('@/views/portfolio/portfolio-list/PortfolioList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/portfolio/portfolio-edit/:id',
    name: 'portfolio-edit',
    component: () => import('@/views/portfolio/portfolio-add/PortfolioEdit.vue'),
    meta: {
      navActiveLink: 'portfolio-list',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/portfolio/portfolio-add',
    name: 'portfolio-add',
    component: () => import('@/views/portfolio/portfolio-add/PortfolioAdd.vue'),
    meta: {
      navActiveLink: 'portfolio-list',
      Permission: 'no_permission_required',
    },
  },
];

export default [
  {
    path: '/supports/supports-list',
    name: 'supports-list',

    component: () => import('@/views/supports/SupportsList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/supports/supports-dashboard',
    name: 'supports-dashboard',
    component: () => import('@/views/supports/SupportsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },

  {
    path: '/supports/supports-dashboard/:filter',
    name: 'supports-dashboard-filter',
    component: () => import('@/views/supports/SupportsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'supports-dashboard',
    },
  },
  {
    path: '/supports/supports-dashboard/team/:team',
    name: 'supports-dashboard-team',
    component: () => import('@/views/supports/SupportsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'supports-dashboard',
    },
  },

  {
    path: '/supports/supports-dashboard/status/:status',
    name: 'supports-dashboard-status',
    component: () => import('@/views/supports/SupportsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'supports-dashboard',
    },
  },

  {
    path: '/supports/supports-add',
    name: 'supports-add',
    component: () => import('@/views/supports/SupportsAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/supports/supports-edit/:id',
    name: 'supports-edit',
    component: () => import('@/views/supports/SupportsEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];

export default [
  {
    path: "/todo/todo-dashboard",
    name: "todo-dashboard",
    component: () => import("@/views/todo/TodoDashboard.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/todo/card-add",
    name: "card-add",
    component: () => import("@/views/todo/AddProject.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
